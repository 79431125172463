import { Button } from '@mui/material';
import { auth, provider } from '../../firebase';
import { signInWithPopup } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const GoogleAuthButton = () => {
  const navigate = useNavigate();
  const signInWithGoogle = () => {
    signInWithPopup(auth, provider)
      .then((e) => {
        navigate('/');
      })
      .catch((e) => {
        alert(e.message);
      });
  };

  return (
    <Button
      variant="outlined"
      onClick={signInWithGoogle}
      className="sign_googleWithButton"
    >
      Googleでログイン
    </Button>
  );
};

export default GoogleAuthButton;
