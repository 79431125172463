import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button } from '@mui/material';
import { AuthContext } from '../../context/AuthContext';
import { UserPostsContext } from '../../App';
import { useContext, useState, useEffect } from 'react';
import { db } from '../../firebase';
import { setDoc, doc } from 'firebase/firestore';

// type Props = {
//   id: string;
//   variant: 'text' | 'outlined' | 'contained' | undefined;
// };

const AddButton = (props) => {
  // ログイン情報
  const authContext = useContext(AuthContext);

  // userのお気に入り情報
  const userPostsContext = useContext(UserPostsContext);

  const [funderState, setFunderState] = useState(true);

  useEffect(() => {
    if (userPostsContext?.array) {
      userPostsContext?.array.map((value) => {
        if (value.id === props.id) {
          setFunderState(false);
        }
      });
    }
  }, [props.id, userPostsContext]);

  const handleAddFunder = async (e) => {
    let array = userPostsContext.array;
    let docData = {};
    if (e) {
      // お気に入りに登録
      array.push({ type: props.funderKey, id: props.id });
      docData = {
        array,
      };
      await setDoc(doc(db, 'users', authContext.userAuth.uid), docData);
    } else {
      // お気に入りから削除
      array = array.filter((obj) => obj.id !== props.id);
      docData = {
        array,
      };
      await setDoc(doc(db, 'users', authContext.userAuth.uid), docData);
    }
  };

  return (
    <>
      {authContext.userAuth ? (
        <Button
          variant={props.variant}
          className="addButton"
          onClick={() => {
            handleAddFunder(funderState);
          }}
        >
          {funderState ? <AddRender /> : <DeleteRender />}
        </Button>
      ) : null}
    </>
  );
};

export const AddRender = (props) => {
  return (
    <>
      <AddIcon />
      <span className="addButtonText">お気に入り追加</span>
    </>
  );
};

export const DeleteRender = () => {
  return (
    <>
      <DeleteIcon />
      <span className="addButtonText">お気に入り削除</span>
    </>
  );
};

export default AddButton;
