import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ListBlock from '../blocks/ListBlock';

const Result = (props: any) => {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState(props.data.fctr);
  const [funder, setFunder] = useState('fctr');

  console.log(props);

  const q1 = useParams().q1;
  const q2 = useParams().q2;
  const q3 = useParams().q3;

  useEffect(() => {
    setTimeout(() => {
      setOpen(false);
    }, 700);

    q3f();
  }, []);

  // const q1f = () => {
  //   if (q1 == '今すぐに必要') {
  //     setData(props.data.fctr);
  //     setFunder('fctr');
  //   } else {
  //     setData(props.data.fctr);
  //     setFunder('fctr');
  //   }
  // };

  // const q2f = () => {
  //   if (q2 == '法人') {
  //     setData(props.data.fctr);
  //     setFunder('fctr');
  //   } else {
  //     setData(props.data.fctr);
  //     setFunder('fctr');
  //   }
  // };

  const q3f = () => {
    if (q3 == '持っている') {
      let returnData = props.data.fctr;
      setData(returnData);
      setFunder('fctr');
    } else {
      let returnData = props.data.rlet;
      setData(returnData);
      setFunder('rlet');
    }
  };

  return (
    <>
      <ListBlock
        data={data}
        title={`${q1}、${q2}、売掛金を${q3}あなたにおすすめ`}
        funderKey={funder}
      ></ListBlock>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};

export default Result;
