import { Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';

type Props = {
  text: string;
  to: string;
};

const LinkButton = (props: Props) => {
  const style = {
    width: 'min(100%, 320px)',
  };
  return (
    <Box textAlign="center" sx={{ mt: 5 }}>
      <Link to={props.to}>
        <Button variant="contained" size="large" {...{ style }}>
          <span>{props.text}</span>
        </Button>
      </Link>
    </Box>
  );
};

export default LinkButton;
