import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialogSlide = (props: any) => {
  const [one, set0ne] = useState({
    title: '今すぐに必要',
    selector1: true,
    selector2: false,
  });
  const [two, setTwo] = useState({
    title: '個人事業主',
    selector1: false,
    selector2: true,
  });
  const [three, setThree] = useState({
    title: '持っている',
    selector1: true,
    selector2: false,
  });

  return (
    <Dialog
      open={props.q.threeQuestion}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        props.q.setThreeQuestion(false);
      }}
    >
      <div className="threeQuestion">
        <div className="threeQuestion_box">
          <h2 className="threeQuestion_box_title">
            {'Q1 今すぐに資金が必要ですか'}
          </h2>
          <Button
            className="threeQuestion_box_but threeQuestion_margin"
            variant={one.selector1 ? 'contained' : 'outlined'}
            onClick={() => {
              set0ne({
                title: '今すぐに必要',
                selector1: true,
                selector2: false,
              });
            }}
          >
            今すぐに必要
          </Button>
          <Button
            className="threeQuestion_box_but"
            variant={one.selector2 ? 'contained' : 'outlined'}
            onClick={() => {
              set0ne({
                title: '時間に余裕がある',
                selector1: false,
                selector2: true,
              });
            }}
          >
            時間に余裕がある
          </Button>
        </div>
        <div className="threeQuestion_box">
          <h2 className="threeQuestion_box_title">
            {'Q2 法人ですか? 個人事業主ですか?'}
          </h2>
          <Button
            className="threeQuestion_box_but threeQuestion_margin"
            variant={two.selector1 ? 'contained' : 'outlined'}
            onClick={() => {
              setTwo({
                title: '法人',
                selector1: true,
                selector2: false,
              });
            }}
          >
            法人
          </Button>
          <Button
            className="threeQuestion_box_but"
            variant={two.selector2 ? 'contained' : 'outlined'}
            onClick={() => {
              setTwo({
                title: '個人事業主',
                selector1: false,
                selector2: true,
              });
            }}
          >
            個人事業主
          </Button>
        </div>

        <div className="threeQuestion_box">
          <h2 className="threeQuestion_box_title">
            {'Q3 売掛金、請求書はお持ちですか?'}
          </h2>
          <Button
            className="threeQuestion_box_but threeQuestion_margin"
            variant={three.selector1 ? 'contained' : 'outlined'}
            onClick={() => {
              setThree({
                title: '持っている',
                selector1: true,
                selector2: false,
              });
            }}
          >
            持っている
          </Button>
          <Button
            className="threeQuestion_box_but"
            variant={three.selector2 ? 'contained' : 'outlined'}
            onClick={() => {
              setThree({
                title: '持っていない',
                selector1: false,
                selector2: true,
              });
            }}
          >
            持っていない
          </Button>
        </div>
        <div className="threeQuestion_box">
          <Link
            to={'./result/' + one.title + '/' + two.title + '/' + three.title}
          >
            <Button
              variant="contained"
              onClick={() => {
                props.q.setThreeQuestion(false);
              }}
              className="threeQuestion_submit"
            >
              送 信
            </Button>
          </Link>
        </div>
      </div>
    </Dialog>
  );
};

export default AlertDialogSlide;
