import { useState } from 'react';
import { Container } from '@mui/system';
import { Link, useNavigate } from 'react-router-dom';
import { TextField, Card, CardContent, Button } from '@mui/material';
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from 'firebase/auth';
import { auth } from '../../firebase';
import { GoogleAuthButton } from './';
import OneColumnBlock from '../blocks/OneColumnBlock';

const Signup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        sendEmailVerification(userCredential.user).then(() => {
          alert('メールを送信しました');
        });
        navigate('/mypage');
      })
      .catch((e) => {
        alert(e.message);
      });
  };

  return (
    <OneColumnBlock useClass="">
      <Container maxWidth="xs">
        <Card sx={{ minWidth: 275 }}>
          <CardContent className="sign">
            <h3 className="sign_title">新規登録</h3>
            <form onSubmit={handleSubmit} className="sign_form">
              <div className="sign_form_div">
                <TextField
                  id="input1"
                  label="Email"
                  variant="standard"
                  type="email"
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  style={{ width: '100%' }}
                />
              </div>
              <div className="sign_form_div">
                <TextField
                  id="input2"
                  label="Password (6文字以上)"
                  variant="standard"
                  type="password"
                  onChange={(e) => setPassword(e.currentTarget.value)}
                  style={{ width: '100%' }}
                />
              </div>
              <Button
                variant="contained"
                type="submit"
                className="sign_form_button"
              >
                新規登録
              </Button>
            </form>
            <GoogleAuthButton />
            <Link to="/signin" className="sign_link">
              ログインはこちら
            </Link>
          </CardContent>
        </Card>
      </Container>
    </OneColumnBlock>
  );
};

export default Signup;
