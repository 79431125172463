import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore"
import { getAuth, GoogleAuthProvider } from "firebase/auth"
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyAJjQ-fO1gnONWEt6gOUGAId4qY2Ht8AFI",
  authDomain: "fundstarmarket.firebaseapp.com",
  projectId: "fundstarmarket",
  storageBucket: "fundstarmarket.appspot.com",
  messagingSenderId: "804900804364",
  appId: "1:804900804364:web:0de2ac63e9f0617f4d1b29",
  measurementId: "G-0J3MLF7CKF"
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const analytics = getAnalytics(app);

export { db, auth, provider, analytics };