import ListBlock from '../blocks/ListBlock';
type Props = {
  data: any;
  title: string;
  funderKey: string;
};

const FunderListPage = (props: Props) => {
  return (
    <ListBlock
      data={props.data}
      title={props.title}
      funderKey={props.funderKey}
    />
  );
};

export default FunderListPage;
