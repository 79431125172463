import { useParams } from 'react-router-dom';
import TwoColumnBlock from '../blocks/TwoColumnBlock';
import FunderListItem from '../modules/FunderListItem';

const Tag = (props) => {
  const keyWord = useParams().keyword;

  return (
    <TwoColumnBlock data={''} itemKey={''}>
      <h2 className="title">{keyWord}</h2>
      <div className="fbw">
        {Object.entries(props.data.bnsl).map(([key, value]) => {
          let tags = value.tags;
          if (value.displayState && tags.includes(keyWord)) {
            return (
              <FunderListItem
                key={key}
                data={value}
                itemKey={key}
                funderKey="bnsl"
              />
            );
          }
          return null;
        })}
        {Object.entries(props.data.fctr).map(([key, value]) => {
          let tags = value.tags;
          if (value.displayState && tags.includes(keyWord)) {
            return (
              <FunderListItem
                key={key}
                data={value}
                itemKey={key}
                funderKey="fctr"
              />
            );
          }
          return null;
        })}
        {Object.entries(props.data.rlet).map(([key, value]) => {
          let tags = value.tags;
          if (value.displayState && tags.includes(keyWord)) {
            return (
              <FunderListItem
                key={key}
                data={value}
                itemKey={key}
                funderKey="rlet"
              />
            );
          }
          return null;
        })}
        {Object.entries(props.data.cafl).map(([key, value]) => {
          let tags = value.tags;
          if (value.displayState && tags.includes(keyWord)) {
            return (
              <FunderListItem
                key={key}
                data={value}
                itemKey={key}
                funderKey="cafl"
              />
            );
          }
        })}
      </div>
    </TwoColumnBlock>
  );
};

export default Tag;
