import OneColumnBlock from '../blocks/OneColumnBlock';
const ErrorPage = () => {
  return (
    <OneColumnBlock useClass={'error'}>
      <h2 className="error_title">404 Error</h2>
      <h3 className="error_c">Not found</h3>
    </OneColumnBlock>
  );
};

export default ErrorPage;
