import { TwoColumnPageSectionBlock } from '../blocks';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../../firebase';
import { doc, getDoc } from 'firebase/firestore';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const Article = (props) => {
  const [posts, setPosts] = useState([]);
  const [text, setText] = useState('');
  const [data, setData] = useState({});
  const [fundState, setFundState] = useState('');
  const [recommendTitle, setRecommendTitle] = useState('');
  const id = useParams().id;

  useEffect(() => {
    (async () => {
      const docRef = doc(db, 'posts', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setPosts(docSnap.data());
        setText(docSnap.data().text.replaceAll('\\n', '\n'));
        setData(props.data[docSnap.data().recommend]);
        setFundState(docSnap.data().recommend);
      }

      if (docSnap.data().recommend === 'fctr') {
        setRecommendTitle('こちらのファクタリング企業がおすすめです');
      } else if (docSnap.data().recommend === 'rlet') {
        setRecommendTitle('こちらの不動産ローンがおすすめです');
      } else {
        setRecommendTitle('おすすめの資金調達先');
      }
    })();
  }, [id]);

  return (
    <TwoColumnPageSectionBlock>
      <Helmet>
        <title>{`${posts.title} - fundstarmarket.com`}</title>
      </Helmet>
      <img
        src={require('../../assets/images/articles/' + id + '.jpg')}
        className="article_eyechatch"
        alt=""
      />
      <div id="twoColumn_pageSection_body" className="article_body">
        <h1 className="article_body_title">{posts.title}</h1>
        <div className="article_body_text">
          <ReactMarkdown>{text}</ReactMarkdown>
        </div>

        <div className="article_recommends">
          <h3 className="article_recommends_title">{recommendTitle}</h3>
          <div className="fbw">
            {Object.entries(data).map(([key, value]) => {
              if (value.exState) {
                return (
                  <div key={key} className="article_recommends_box">
                    <Link to={`/funder/${fundState}/${key}`}>
                      <div className="article_recommends_box_div">
                        <h2 className="article_recommends_box_div_companyName">
                          {value.companyName}
                        </h2>
                        <p className="article_recommends_box_div_catchCopy">
                          {value.catchCopy}
                        </p>
                      </div>
                      <img
                        src={require(`../../assets/images/funders/${key}_m.jpg`)}
                        alt=""
                        className="article_recommends_box_img"
                      />
                    </Link>
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      </div>
    </TwoColumnPageSectionBlock>
  );
};

export default Article;
