import TwoColumnBlock from './TwoColumnBlock';
import FunderListItem from '../modules/FunderListItem';
// type Props = {
//   data: any,
//   title: string,
// };
const ListBlock = (props) => {
  return (
    <TwoColumnBlock data={''} itemKey={''}>
      <h2 className="title">{props.title}</h2>
      <div className="fbw">
        {Object.entries(props.data).map(([key, value]) => {
          if (value.displayState) {
            return (
              <FunderListItem
                key={key}
                data={value}
                itemKey={key}
                funderKey={props.funderKey}
              />
            );
          }
          return null;
        })}
      </div>
    </TwoColumnBlock>
  );
};

export default ListBlock;
