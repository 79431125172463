import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import HomeExCard from './HomeExCard';

const settings = {
  autoplay: true,
  dots: false,
  infinite: true,
  speed: 1500,
  autoplaySpeed: 3000,
  slidesToScroll: 1,
  variableWidth: true,
  centerMode: true,
};

const HomeEx = (props) => {
  const data = props.data.fctr;

  return (
    <section className="home_ex home_section">
      <h2 className="home_title">優良な企業を多数掲載しています</h2>
      <Slider {...settings}>
        {Object.entries(data).map(([key, value]) => {
          if (!value.exState) {
            return null;
          }
          return (
            <HomeExCard
              data={value}
              itemKey={key}
              funderKey="fctr"
              key={key}
              cls="home_ex_card"
            />
          );
        })}
      </Slider>
      {/* <LinkButton text={'優良企業一覧'} to={'/list/ex'} /> */}
    </section>
  );
};

export default HomeEx;
