import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import ReactMarkdown from 'react-markdown';
import { TwoColumnPageSectionBlock } from '../blocks';
import { BasicRating } from '../modules';
import { Helmet } from 'react-helmet-async';
// type Props = {
//   data: any;
// };

const FunderPage = (props) => {
  const navigate = useParams();
  const funder = navigate.funder;
  const id = navigate.id;
  const data = props.data[funder][id];

  return (
    <TwoColumnPageSectionBlock data={data} itemKey={id}>
      <Helmet>
        <title>{`資金調達なら ${data.companyName} - fundstarmarket.com`}</title>
      </Helmet>
      <img
        src={require(`../../assets/images/funders/${id}.jpg`)}
        id="twoColumn_pageSection_eyeCatch"
        alt={data.companyName}
      />

      <div id="twoColumn_pageSection_body" className="funderPage">
        <div className="flex funderPage_points">
          {data.points.map((value, index) => {
            return (
              <span key={index.toString()} className="funderPage_points_point">
                {value}
              </span>
            );
          })}
        </div>
        {/* <strong className="funderPage_catchCopy">{data.catchCopy}</strong> */}
        <span className="funderPage_register">更新: {data.register}</span>
        <h2 className="funderPage_companyName">{data.companyName}</h2>

        {data.body.map((value, index) => {
          return (
            <React.Fragment key={index.toString()}>
              <h3 className="funderPage_title" key={index}>
                {value.title}
              </h3>
              <div className="funderPage_body">
                <ReactMarkdown>
                  {value.body.replaceAll('\\n', '\n')}
                </ReactMarkdown>
              </div>
            </React.Fragment>
          );
        })}
        <h3 className="funderPage_title">所在地</h3>
        {data.resion.map((value, index) => {
          return (
            <React.Fragment key={index.toString()}>
              {value.title ? (
                <h4 className="funderPage_resionTitle">{value.title}</h4>
              ) : null}
              {value.postalNo ? (
                <p className="funderPage_body">〒 {value.postalNo}</p>
              ) : null}
              {value.address ? (
                <p className="funderPage_body">{value.address}</p>
              ) : null}
            </React.Fragment>
          );
        })}
        {data.review.length ? (
          <h3 className="funderPage_reviewTitle">利用者のレビュー</h3>
        ) : (
          ''
        )}

        {data.review.map((value, index) => {
          return (
            <div key={index.toString()} className="funderPage_review">
              <div className="fac">
                <BasicRating rating={value.rating} />
                <span className="funderPage_review_industry">
                  {value.industry}
                </span>
              </div>
              <h3 className="funderPage_review_title">{value.title}</h3>
              <ReactMarkdown>
                {value.body.replaceAll('\\n', '\n')}
              </ReactMarkdown>
              <PersonIcon className="funderPage_review_icon" fontSize="large" />
            </div>
          );
        })}
        <amp-pixel src={data.afImgUrl} layout="nodisplay"></amp-pixel>
        <a
          href={data.afUrl ? data.afUrl : data.url}
          target="_blank"
          rel="noreferrer"
          className="funderPage_toWrap"
        >
          <Button
            variant="contained"
            className="funderPage_toWrap_to"
            size="large"
          >
            {data.companyName}にお申し込み
          </Button>
        </a>
      </div>
    </TwoColumnPageSectionBlock>
  );
};

export default FunderPage;
