import { Button } from '@mui/material';
import { AddButton } from '../modules';
import { Link } from 'react-router-dom';
import PublishBanner from '../../assets/images/publish.jpg';

type Props = {
  data: any;
  itemKey: string;
};

const SideBar = (props: Props) => {
  const tags = [
    'ファクタリング',
    '不動産担保ローン',
    'フリーローン',
    'ビジネスローン',
    '即日',
    '個人事業主',
    'Web契約',
    '翌日',
  ];

  const area = ['宮城', '東京', '神奈川', '福岡', '大阪', '愛知'];

  return (
    <section className="sidebar">
      <h4 className="sidebar_title">タグ</h4>
      <div className="sidebar_tagBox fw">
        {tags.map((value, index) => {
          return (
            <Link
              to={`/tag/${value}`}
              className="sidebar_tagBox_tag fac"
              key={index.toString()}
            >
              {value}
            </Link>
          );
        })}
      </div>
      <h4 className="sidebar_title">エリア</h4>
      <div className="sidebar_tagBox fw">
        {area.map((value, index) => {
          return (
            <Link
              to={`/tag/${value}`}
              className="sidebar_tagBox_tag fac"
              key={index.toString()}
            >
              {value}
            </Link>
          );
        })}
      </div>

      <div className="sidebar_sticky">
        {props.data ? (
          <div className="sidebar_sticky_funderItem">
            <AddButton
              id={props.itemKey}
              variant="outlined"
              funderKey={'fctr'}
            />
            <a
              href={props.data.afUrl ? props.data.afUrl : props.data.url}
              target="_blank"
              rel="noreferrer"
            >
              <Button variant="contained" className="sidebar_sticky_button">
                {props.data.companyName}に<br />
                お申し込み
              </Button>
            </a>
          </div>
        ) : null}
        <Link to="/publishcontact">
          <img src={PublishBanner} alt="" className="sidebar_banner" />
        </Link>
      </div>
    </section>
  );
};

export default SideBar;
