import TwoColumnBlock from '../blocks/TwoColumnBlock';
import { useState, useEffect } from 'react';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { db } from '../../firebase';
import ArticleListItem from '../modules/ArticleListItem';

const Articles = () => {
  const [posts, setPosts] = useState([]);
  const data = [];

  useEffect(() => {
    const postsData = collection(db, 'posts');
    const q = query(postsData, orderBy('timestamp', 'desc'));

    (async () => {
      await getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let title = doc.data().title;
          let text = doc.data().text;
          text = text.replaceAll('\\n', '\n');
          text = text.substring(0, 80) + '...';
          let timestamp = doc.data().timestamp;
          timestamp = timestamp.toDate();
          let month = timestamp.getMonth() + 1;
          timestamp =
            timestamp.getFullYear() + '/' + month + '/' + timestamp.getDate();
          data.push({
            id: doc.id,
            title: title,
            text: text,
            timestamp: timestamp,
          });
        });
        setPosts(data);
      });
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TwoColumnBlock>
      <div className="fbw">
        {posts.map((value, index) => {
          return <ArticleListItem key={index.toString()} data={value} />;
        })}
      </div>
    </TwoColumnBlock>
  );
};

export default Articles;
