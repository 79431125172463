import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { AddButton, DetailButon } from './';
import {
  ReactElement,
  JSXElementConstructor,
  ReactFragment,
  ReactPortal,
} from 'react';

type Props = {
  data: any;
  funderKey: string;
  itemKey: string;
};

const FunderListItem = (props: Props) => {
  return (
    <Card className="listItem">
      <Link to={`/funder/${props.funderKey}/${props.itemKey}`}>
        <CardMedia
          component="img"
          height="140"
          image={require('../../assets/images/funders/' +
            props.itemKey +
            '_m.jpg')}
          alt="green iguana"
        />
      </Link>
      <CardContent>
        <h3 className="listItem_title">{props.data.companyName}</h3>
        <p className="listItem_catchCopy">{props.data.catchCopy}</p>
        <div className="flex listItem_points">
          {props.data.points.map(
            (
              value:
                | string
                | number
                | boolean
                | ReactElement<any, string | JSXElementConstructor<any>>
                | ReactFragment
                | ReactPortal
                | null
                | undefined,
              index: number
            ) => {
              return (
                <span key={index.toString()} className="listItem_points_point">
                  {value}
                </span>
              );
            }
          )}
        </div>
        <div className="listItem_actions">
          <AddButton
            id={props.itemKey}
            variant={'text'}
            funderKey={props.funderKey}
          />
          <DetailButon to={`/funder/${props.funderKey}/${props.itemKey}`} />
        </div>
      </CardContent>
    </Card>
  );
};

export default FunderListItem;
