import { useContext, useEffect, useState, createContext } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Header, Footer, ThreeQuestionBlock } from './components/blocks';
import { AuthContext } from './context/AuthContext';
import { db } from './firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import { ScrollToTop } from './components/modules';
import Router from './components/Router';
import data from './assets/data/FunderDataset';

export const UserPostsContext = createContext(undefined);

function App() {
  const [threeQuestion, setThreeQuestion] = useState(false);
  const [userPosts, setUserPosts] = useState();
  const authContext = useContext(AuthContext);

  useEffect(() => {
    // authを確認
    onAuthStateChanged(auth, (user) => {
      user
        ? authContext?.setUserAuth(auth.currentUser)
        : authContext?.setUserAuth(null);
    });

    // firebase uesrPosts取得
    (async () => {
      const docRef = doc(db, 'users', authContext?.userAuth.uid);
      await getDoc(docRef)
        .then((docSnap) => {
          if (docSnap.data()) {
            setUserPosts(docSnap.data());
          } else {
            // usersに初期値を登録
            setDoc(doc(db, 'users', authContext?.userAuth.uid), {
              array: [{ type: 'init', id: '0000' }],
            });
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    })();
  }, [authContext, authContext?.userAuth]);

  return (
    <BrowserRouter>
      <UserPostsContext.Provider value={userPosts}>
        <Header />
        <ScrollToTop />
        <Router
          setThreeQuestion={setThreeQuestion}
          data={data}
          userPosts={userPosts}
        />
        <ThreeQuestionBlock q={{ threeQuestion, setThreeQuestion }} />
        <Footer />
      </UserPostsContext.Provider>
    </BrowserRouter>
  );
}

export default App;
