import { AuthContext } from '../../context/AuthContext';
import { useContext } from 'react';
import { OneColumnBlock } from '../blocks';
import { HomeEx } from '../blocks/home';
import { UserPostsContext } from '../../App';
import { ExCard } from '../modules';
import { Link } from 'react-router-dom';

const MyPage = (props) => {
  const authContext = useContext(AuthContext);
  const userPostContext = useContext(UserPostsContext);
  return (
    <OneColumnBlock useClass={''}>
      {authContext?.userAuth ? (
        <div className="myPage_account">
          <h2 className="myPage_title">お気に入り</h2>
          {userPostContext ? (
            <div className="w m p fw">
              {userPostContext.array.map((value, index) => {
                if (value.type !== 'init') {
                  return (
                    <ExCard
                      key={index.toString()}
                      data={props.data[value.type][value.id]}
                      itemKey={value.id}
                      funderKey={value.type}
                    ></ExCard>
                  );
                }
                return null;
              })}
            </div>
          ) : null}
        </div>
      ) : (
        <div className="myPage_noccount">
          <p>アカウントはお持ちですか？</p>
          <Link to="/signin">ログイン</Link>
          <Link to="/signup">新規登録</Link>
        </div>
      )}
      <HomeEx data={props.data} />
    </OneColumnBlock>
  );
};
export default MyPage;
