import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button } from '@mui/material';

type Props = {
  data: any;
};

const ArticleListItem = (props: Props) => {
  return (
    <Card className="listItem">
      <Link to={'/article/' + props.data.id}>
        <CardMedia
          component="img"
          height="140"
          image={require('../../assets/images/articles/' +
            props.data.id +
            '_m.jpg')}
          alt="green iguana"
        />
      </Link>
      <span className="listItem_timestamp">{props.data.timestamp}</span>
      <CardContent>
        <h3 className="listItem_title">{props.data.title}</h3>
        <p className="listItem_text">{props.data.text.replaceAll('#', '')}</p>
      </CardContent>
      <Link to={'/article/' + props.data.id} className="listItem_link">
        <Button
          variant="outlined"
          size="small"
          className="listItem_link_button"
        >
          続きはこちら
        </Button>
      </Link>
    </Card>
  );
};

export default ArticleListItem;
