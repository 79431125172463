type Props = {
  children: React.ReactNode;
  useClass: string;
};

const OneColumnBlock = (props: Props) => {
  return (
    <main
      id="oneColumn"
      className={props.useClass ? props.useClass : 'oneColumn'}
    >
      {props.children}
    </main>
  );
};

export default OneColumnBlock;
