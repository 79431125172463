import { TwoColumnPageSectionBlock } from '../blocks';
import { useState } from 'react';
import {
  TextField,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
} from '@mui/material';

const FundingForm = () => {
  const [email, setEmail] = useState('');
  const [company, setCompany] = useState('');
  const [businessForm, setBusinessForm] = useState('');
  const [body, setBody] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const payload = {
      text:
        'fund star market相談フォームにお問合せ\n' +
        '会社名:\n' +
        company +
        '\n\n' +
        'email:\n' +
        email +
        '\n\n' +
        'businessForm:\n' +
        businessForm +
        '\n\n' +
        'body:\n' +
        body,
    };

    const url =
      'https://hooks.slack.com/services/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';

    fetch(url, {
      method: 'post',
      body: JSON.stringify(payload),
    }).then(() => {
      alert(
        '送信が完了しました。\n送信いただいた内容を確認の上、Emailに返信いたしますのでしばらくお待ちください。'
      );
      setEmail('');
      setCompany('');
      setBody('');
    });
  };

  const handleChangeCompany = (e) => {
    setCompany(e.currentTarget.value);
  };

  const handleChangeEmail = (e) => {
    setEmail(e.currentTarget.value);
  };

  const handleChangeBusinessForm = (e) => {
    setBusinessForm(e);
  };

  const handleChangeBody = (e) => {
    setBody(e.currentTarget.value);
  };

  return (
    <TwoColumnPageSectionBlock>
      <div id="twoColumn_pageSection_body" className="static">
        <h2 className="title2">資金調達のご相談</h2>
        <p className="text">
          経営者様、個人事業主様の資金調達のお悩みご相談ください。
        </p>
        <ul className="ul">
          <li className="contact_ul_li">即日で資金が必要</li>
          <li className="contact_ul_li">1億以上の大きな額の資金調達がしたい</li>
          <li className="contact_ul_li">税金を滞納している</li>
          <li className="contact_ul_li">経営が赤字続き</li>
          <li className="contact_ul_li">融資の審査に通る気がしない</li>
          <li className="contact_ul_li">債務の整理がしたい</li>
        </ul>
        <p className="text">
          等あなたの状況に合わせて、fund star
          marketが最適な資金調達先をご紹介いたします。
        </p>
        <form onSubmit={handleSubmit} className="commonForm">
          <h2 className="title2">ご相談 Form</h2>
          <div className="commonForm_div">
            <TextField
              id="input1"
              label="会社名"
              variant="standard"
              type="text"
              onChange={(e) => handleChangeCompany(e)}
              defaultValue={company}
              style={{ width: '100%' }}
            />
          </div>
          <div className="commonForm_div2">
            <TextField
              id="input2"
              label="Email"
              variant="standard"
              type="email"
              required
              onChange={(e) => handleChangeEmail(e)}
              defaultValue={email}
              style={{ width: '100%' }}
            />
          </div>
          <div className="commonForm_div2">
            <FormControl>
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
              >
                <FormControlLabel
                  value="法人"
                  control={<Radio />}
                  label="法人"
                  onChange={(e) => {
                    handleChangeBusinessForm('法人');
                  }}
                />
                <FormControlLabel
                  value="個人事業主"
                  control={<Radio />}
                  label="個人事業主"
                  onChange={(e) => {
                    handleChangeBusinessForm('個人事業主');
                  }}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="commonForm_div2">
            <textarea
              name=""
              id=""
              cols="40"
              rows="8"
              className="commonForm_textArea"
              placeholder="ご相談内容"
              onChange={(e) => {
                handleChangeBody(e);
              }}
            ></textarea>
          </div>
          <Button variant="contained" type="submit" className="">
            送 信
          </Button>
        </form>
      </div>
    </TwoColumnPageSectionBlock>
  );
};

export default FundingForm;
