import { createContext, useState } from 'react';

type Props = {
  children: React.ReactNode;
};

type InitioalState = {
  userAuth: any;
  setUserAuth: any;
};

export const AuthContext = createContext<InitioalState | null>(null);

export const AuthProvider = (props: Props) => {
  const [userAuth, setUserAuth] = useState(false);

  return (
    <AuthContext.Provider value={{ userAuth, setUserAuth }}>
      {props.children}
    </AuthContext.Provider>
  );
};
