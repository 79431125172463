import { useState } from 'react';
import { TextField, Button } from '@mui/material';
import { TwoColumnPageSectionBlock } from '../blocks';

const PublishContactPage = () => {
  const [email, setEmail] = useState('');
  const [publishUrl, setPublishUrl] = useState(newFunction());
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const payload = {
      text:
        'fund star market掲載希望にお問合せ\n' +
        '掲載希望URL:' +
        publishUrl +
        '\n' +
        'email:' +
        email,
    };

    const url =
      'https://hooks.slack.com/services/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';

    fetch(url, {
      method: 'post',
      body: JSON.stringify(payload),
    }).then(() => {
      alert(
        '送信が完了しました。\n送信いただいたURLを確認の上掲載の可否を決めさせていただきます。\n1~2週間ほどで結果をEmailに送信いたします。'
      );
      setEmail('');
      setPublishUrl('');
    });
  };
  function handleChangeURL(e: any) {
    setPublishUrl(e.currentTarget.value);
  }

  function handleChangeEmail(e: any) {
    setEmail(e.currentTarget.value);
  }

  return (
    <TwoColumnPageSectionBlock data={undefined} itemKey={''}>
      <div id="twoColumn_pageSection_body">
        <h2 className="title2">掲載のお申込み</h2>
        <p className="text">
          fund star
          marketでは資金調達や経営の専門家の掲載申込みを募集しております。
        </p>
        <ul className="ul">
          <li>税理士</li>
          <li>社会保健労務士</li>
          <li>中小企業診断士</li>
          <li>行政書士</li>
          <li>司法書士</li>
          <li>公認会計士</li>
          <li>ファイナンシャルプランナー</li>
          <li>経営・財務コンサルタント</li>
          <li>ファクタリング業社</li>
          <li>ビジネスローン取扱業社</li>
          <li>その他、事業や事業資金調達に関する業社や専門家</li>
        </ul>
        <p className="text">
          上記企業様や専門家の方など幅広い業種の掲載を募集しております。
          <br />
          お申し込み・掲載料金は無料となっておりますので、お気軽にお申し込みください。
        </p>
        <form onSubmit={handleSubmit} className="commonForm">
          <h2 className="title2">掲載申込み Form</h2>
          <div className="commonForm_div">
            <TextField
              required
              id="input1"
              label="掲載希望サイトURL"
              variant="standard"
              type="text"
              onChange={(e) => handleChangeURL(e)}
              style={{ width: '100%' }}
            />
          </div>
          <div className="commonForm_div2">
            <TextField
              required
              id="input2"
              label="ご連絡用Email"
              variant="standard"
              type="email"
              onChange={(e) => handleChangeEmail(e)}
              style={{ width: '100%' }}
            />
          </div>

          <Button
            variant="contained"
            type="submit"
            className="publishContact_withButton"
          >
            送 信
          </Button>
        </form>
      </div>
    </TwoColumnPageSectionBlock>
  );

  function newFunction(): string | (() => string) {
    return '';
  }
};

export default PublishContactPage;
