import { auth } from '../../firebase';
// import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';

const GoogleAuthSignOutButton = () => {
  // const navigate = useNavigate();
  const signOut = () => {
    auth
      .signOut()
      .then(() => {
        alert('サインアウトしました');
        // navigate('/');
      })
      .catch((err) => {
        alert(err.message);
      });
  };

  return (
    <Button variant="text" size="small" onClick={signOut}>
      ログアウト
    </Button>
  );
};

export default GoogleAuthSignOutButton;
