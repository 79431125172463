import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Container } from '@mui/system';
import { TextField, Card, CardContent, Button } from '@mui/material';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';
import OneColumnBlock from '../blocks/OneColumnBlock';

const PasswordReset = () => {
  const [email, setEmail] = useState('');
  const handleSubmit = (e: any) => {
    e.preventDefault();
    sendPasswordResetEmail(auth, email)
      .then(() => {
        alert('パスワードリセット用のメールを送信しましたのでご確認ください。');
      })
      .catch((e) => {});
  };

  const handleChangeEmail = (e: any) => {
    setEmail(e.currentTarget.value);
  };

  return (
    <OneColumnBlock useClass="">
      <Container maxWidth="xs">
        <Card>
          <CardContent className="sign">
            <h3 className="sign_title">パスワードリセット</h3>
            <form onSubmit={handleSubmit} className="sign_form">
              <div className="sign_form_div">
                <TextField
                  id="input1"
                  label="Email"
                  variant="standard"
                  type="email"
                  onChange={(e) => handleChangeEmail(e)}
                  style={{ width: '100%' }}
                />
              </div>

              <Button
                variant="contained"
                type="submit"
                className="sign_form_button"
              >
                送信
              </Button>
            </form>

            <Link to="/passwordreset" className="sign_link">
              ログインはこちら
            </Link>
            <Link to="/signup" className="sign_link">
              新規登録はこちら
            </Link>
          </CardContent>
        </Card>
      </Container>
    </OneColumnBlock>
  );
};

export default PasswordReset;
