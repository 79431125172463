import Button from '@mui/material/Button';
import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { GoogleAuthSignOutButton } from '../user';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import Logo from '../../assets/images/logoMini.png';

const pages = [
  { title: 'ホーム', to: './' },
  { title: '記事一覧', to: './articles' },
  { title: '資金調達相談', to: './fundingform' },
];

const Header = () => {
  const [isHeightOver, setIsHeightOver] = useState(false);
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    const scrollAction = () => {
      if (50 > window.scrollY) {
        setIsHeightOver(true);
      } else {
        setIsHeightOver(false);
      }
    };
    window.addEventListener('scroll', scrollAction, {
      capture: false,
      passive: true,
    });
    scrollAction();
    return () => {
      window.removeEventListener('scroll', scrollAction);
    };
  }, []);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <header
      className="header"
      id={isHeightOver ? 'headerBackgroundNone' : 'headerBackground'}
    >
      <nav className="fba w p m">
        <div className="fba">
          <Box sx={{ display: { md: 'none' } }}>
            <IconButton aria-label="menu" onClick={handleOpenNavMenu}>
              <MenuIcon />
            </IconButton>
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((item, index) => (
              <MenuItem key={index.toString()} onClick={handleCloseNavMenu}>
                <Link to={item.to} className="header_mbMenu">
                  {item.title}
                </Link>
              </MenuItem>
            ))}
          </Menu>
          <Link to="/">
            <img src={Logo} alt="" className="header_logo" />
          </Link>
          {pages.map((value, index) => {
            return (
              <Link
                to={value.to}
                className="header_menu pc"
                key={index.toString()}
              >
                {value.title}
              </Link>
            );
          })}
        </div>
        <div>
          {authContext?.userAuth ? (
            <>
              <Link to="./myPage" className="header_userBut">
                <Button variant="contained" size="small">
                  マイページ
                </Button>
              </Link>
              <GoogleAuthSignOutButton />
            </>
          ) : (
            <>
              <Link to="./signin" className="header_userBut">
                <Button variant="contained" size="small">
                  ログイン
                </Button>
              </Link>
              <Link to="./signup">
                <Button variant="outlined" size="small">
                  新規登録
                </Button>
              </Link>
            </>
          )}
        </div>
      </nav>
      <nav className=""></nav>
    </header>
  );
};
export default Header;
