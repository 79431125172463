import TwoColumnBlock from './TwoColumnBlock';
type Props = {
  children: React.ReactNode;
  data: any;
  itemKey: string;
};
const TwoColumnPageSectionBlock = (props: Props) => {
  return (
    <TwoColumnBlock data={props.data} itemKey={props.itemKey}>
      <section id="twoColumn_pageSection">{props.children}</section>
    </TwoColumnBlock>
  );
};

export default TwoColumnPageSectionBlock;
