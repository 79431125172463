import { Button } from '@mui/material';
import heroImg from '../../../assets/images/heroImg.jpg';

type Props = {
  setThreeQuestion: any;
};

const HomeHero = (props: Props) => {
  return (
    <section className="home_hero fac">
      <div className="home_hero_body w m p">
        <h2 className="home_hero_body_title">
          3つの質問で
          <br />
          あなたにピッタリの
          <br />
          資金調達をご提案
        </h2>
        <div className="home_hero_body_button">
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              props.setThreeQuestion(true);
            }}
          >
            Q1 今すぐに資金が必要ですか？
          </Button>
        </div>
      </div>
      <img src={heroImg} alt="" className="home_hero_img" />
    </section>
  );
};

export default HomeHero;
