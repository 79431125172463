import { LinkButton } from '../../modules';
import HomeFunderItem from './HomeFunderCard';

// type Props = {
//   title: string;
//   buttonText: string;
//   to: string;
//   data: any;
// };

const HomeSection = (props) => {
  return (
    <section className="home_section">
      <h2 className="home_title">資金調達 {props.title}</h2>
      <div className="w m p fw">
        {Object.entries(props.data).map(([key, value]) => {
          if (value.displayState) {
            return (
              <HomeFunderItem
                data={value}
                itemKey={key}
                key={key}
                funderKey={props.funderKey}
              />
            );
          }
          return null;
        })}
      </div>
      <LinkButton text={props.buttonText} to={props.to} />
    </section>
  );
};

export default HomeSection;
