import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
type Props = {
  to: string;
};
const DetailButon = (props: Props) => {
  return (
    <Link to={props.to}>
      <Button variant="outlined">
        <span className="detailButton">詳細</span>
      </Button>
    </Link>
  );
};

export default DetailButon;
