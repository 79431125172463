import Logo from '../../assets/images/logoMiniWhite.png';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="footer fac">
      <div className="w m p">
        <img src={Logo} alt="" className="footer_logo m" />
        <h6 className="footer_h6">fundstarmarket.com</h6>
        <div className="footer_div">
          <Link to="/privacypolicy" className="footer_div_link">
            プライバシーポリシー
          </Link>
          <Link to="/fundingform" className="footer_div_link">
            資金調達相談
          </Link>
          <Link to="/publishcontact" className="footer_div_link">
            サイト掲載申し込み
          </Link>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
