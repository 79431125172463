import { query, orderBy, limit, collection, getDocs } from 'firebase/firestore';
import { db } from '../../../firebase';
import { useEffect, useState } from 'react';

const HomeNewsList = () => {
  const [news, setNews] = useState([]);
  const data = [];
  useEffect(() => {
    (async () => {
      const newsData = collection(db, 'news');
      const q = query(newsData, orderBy('timestamp', 'desc'), limit(8));
      await getDocs(q).then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let title = doc.data().title;
          let text = doc.data().text.replaceAll('\\n', '\n');
          let timestamp = doc.data().timestamp;
          timestamp = timestamp.toDate();
          let manth = timestamp.getMonth() + 1;
          timestamp =
            timestamp.getFullYear() + '/' + manth + '/' + timestamp.getDate();
          let url = doc.data().url;
          data.push({
            id: doc.id,
            title: title,
            text: text,
            url: url,
            timestamp: timestamp,
          });
        });
        setNews(data);
      });
    })();
  }, []);
  return (
    <section className="home_section">
      <h2 className="home_title">最新 資金調達News</h2>
      <div className="mw m p">
        {news.map((value, index) => {
          return (
            <a
              href={value.url}
              className="home_newsList"
              key={index.toString()}
              target="_blank"
              rel="noreferrer"
            >
              <span className="home_newsList_timestamp">{value.timestamp}</span>
              <h2 className="home_newsList_title">{value.title}</h2>
            </a>
          );
        })}
      </div>
    </section>
  );
};

export default HomeNewsList;
