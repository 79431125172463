import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Link } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import { AddButton, DetailButon } from '../../modules';

type Props = {
  data: any;
  itemKey: string;
  funderKey: string;
};

const HomeExCard = (props: Props) => {
  return (
    <Card sx={{ maxWidth: 345 }} className="home_ex_card">
      <Link to={`/funder/${props.funderKey}/${props.itemKey}`}>
        <img
          src={require(`../../../assets/images/funders/${props.itemKey}_m.jpg`)}
          alt={props.data.companyName}
          className="home_ex_card_img"
        />
      </Link>
      <CardContent>
        <h2 className="home_ex_card_companyName">{props.data.companyName}</h2>
        <p className="home_ex_card_catchCopy">{props.data.catchCopy}</p>
      </CardContent>
      <div className="home_ex_card_action">
        <span className="home_ex_card_action_span">
          <AddButton
            id={props.itemKey}
            variant="text"
            funderKey={props.funderKey}
          />
        </span>
        <DetailButon to={`/funder/fctr/${props.itemKey}`}></DetailButon>
      </div>
      <p className="home_ex_card_tag fac">
        <StarIcon fontSize="small" />
        <span className="home_ex_card_tag_span">優良</span>
      </p>
    </Card>
  );
};

export default HomeExCard;
