import { Routes, Route } from 'react-router-dom';
import {
  HomePage,
  ErrorPage,
  Result,
  FunderListPage,
  FunderPage,
  Article,
  Articles,
  RankingPage,
  FundingFormPage,
  TagPage,
  PublishContactPage,
  Privacypolicy,
} from './pages';
import { SignIn, SignUp, MyPage, PasswordReset } from './user';

type Props = {
  setThreeQuestion: any;
  data: any;
  userPosts: any;
};

const Router = (props: Props) => {
  return (
    <Routes>
      <Route
        path={`/`}
        element={
          <HomePage
            setThreeQuestion={props.setThreeQuestion}
            data={props.data}
          />
        }
      />
      <Route
        path={`/list/businessLoan`}
        element={
          <FunderListPage
            data={props.data.bnsl}
            title="ビジネスローン企業一覧"
            funderKey={'bnsl'}
          />
        }
      />
      <Route
        path={`/list/factoring`}
        element={
          <FunderListPage
            data={props.data.fctr}
            title="ファクタリング企業一覧"
            funderKey={'fctr'}
          />
        }
      />
      <Route
        path={`/list/realestate`}
        element={
          <FunderListPage
            data={props.data.rlet}
            title="不動産取扱企業一覧"
            funderKey={'rlet'}
          />
        }
      />
      <Route path={`/tag/:keyword`} element={<TagPage data={props.data} />} />
      <Route
        path={`/list/ex`}
        element={
          <FunderListPage
            data={props.data.fctr}
            title="優良企業一覧"
            funderKey={'ex'}
          />
        }
      />
      <Route path={`/article/:id`} element={<Article data={props.data} />} />
      <Route path={`/articles/`} element={<Articles />} />
      <Route
        path={`/funder/:funder/:id`}
        element={<FunderPage data={props.data} />}
      />
      <Route path={`/result/`} element={<Result data={props.data} />} />
      <Route path={`/result/:q1`} element={<Result data={props.data} />} />
      <Route path={`/result/:q1/:q2`} element={<Result data={props.data} />} />
      <Route
        path={`/result/:q1/:q2/:q3`}
        element={<Result data={props.data} />}
      />
      <Route path={`/ranking`} element={<RankingPage />} />
      <Route path={`/privacypolicy`} element={<Privacypolicy />} />
      <Route path={`/fundingform`} element={<FundingFormPage />} />
      <Route path={`/publishcontact`} element={<PublishContactPage />} />
      <Route path={`/signin`} element={<SignIn />} />
      <Route path={`/signup`} element={<SignUp />} />
      <Route path={`/passwordreset`} element={<PasswordReset />} />
      <Route path={`/mypage`} element={<MyPage data={props.data} />} />

      <Route path={`*`} element={<ErrorPage />} />
    </Routes>
  );
};

export default Router;
