import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Link } from 'react-router-dom';
import { AddButton, DetailButon } from '../../modules';

type Props = {
  data: any;
  itemKey: string;
  funderKey: string;
};

const HomeFunderCard = (props: Props) => {
  return (
    <Card className="home_section_card">
      <Link to={`/funder/${props.funderKey}/${props.itemKey}`}>
        <CardMedia
          component="img"
          height="194"
          image={require(`../../../assets/images/funders/${props.itemKey}_m.jpg`)}
          alt=""
        />
      </Link>
      <h2 className="home_section_card_title">{props.data.companyName}</h2>
      <p className="home_section_card_text">{props.data.catchCopy}</p>
      <div className="home_section_card_actions">
        <span className="home_section_card_actions_span">
          <AddButton
            id={props.itemKey}
            variant="text"
            funderKey={props.funderKey}
          />
        </span>
        <DetailButon to={`/funder/${props.funderKey}/${props.itemKey}`} />
      </div>
    </Card>
  );
};

export default HomeFunderCard;
