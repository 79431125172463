import SideBar from './SideBar';
type Props = {
  children: React.ReactNode;
  data: any;
  itemKey: string;
};

const TwoColumnBlock = (props: Props) => {
  return (
    <main id="twoColumn" className="fbw w m p">
      <div id="twoColumn_left">{props.children}</div>
      <SideBar data={props.data} itemKey={props.itemKey} />
    </main>
  );
};

export default TwoColumnBlock;
