import { HomeEx, HomeNewsList, HomeHero, HomeSection } from '../blocks/home/';
import { Helmet } from 'react-helmet-async';

type Props = {
  setThreeQuestion: any;
  data: any;
};

const Home = (props: Props) => {
  return (
    <main className="home">
      <Helmet>
        <title>{'資金調達先が見つかる - fundstarmarket.com'}</title>
      </Helmet>
      <HomeHero setThreeQuestion={props.setThreeQuestion} />
      <HomeEx data={props.data} />
      <HomeSection
        data={props.data.bnsl}
        title={'ビジネスローン'}
        buttonText={'ビジネスローン取扱企業一覧'}
        funderKey="bnsl"
        to={'/list/businessLoan'}
      />
      <HomeSection
        data={props.data.rlet}
        title={'不動産買取・ローン'}
        buttonText={'不動産買取・ローン取扱企業一覧'}
        funderKey="rlet"
        to={'/list/realestate'}
      />
      <HomeSection
        data={props.data.fctr}
        title={'ファクタリング'}
        buttonText={'ファクタリング企業一覧'}
        funderKey="fctr"
        to={'/list/factoring'}
      />
      <HomeNewsList />
    </main>
  );
};

export default Home;
